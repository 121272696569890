<template>
  <img
    class="border"
    src="https://timeweb.com/ru/community/article/67/67d62d1e0bc27de113cc0e25239705e2.png"
  />
</template>

<script>
export default {}
</script>

<style></style>
